<template>
  <div class="view">
    <div v-if="!album">
      <p>Loading...</p>
    </div>
    <div v-else>
      <router-link to="/" class="back"
        ><img
          class="desktop_form_close"
          src="@/assets/img/close.svg"
          alt="close"
      /></router-link>

      <div class="headline__container group">
        <label><strong>Release:</strong></label>
        <BaseHeadline maxLength="55" :text="album.album_name" />
      </div>

      <div class="group-col">
        <div class="group hide-on-tablet" style="width: 75%">
          <label class="inline"><strong>Release Artist:</strong></label>
          {{ album.artist_name }}
        </div>
        <div class="group hide-on-tablet" style="width: 25%">
          <label class="inline"><strong>Released:</strong></label>
          {{ album.release_date }}
        </div>
      </div>

      <div class="headings__container">
        <span class="song-title-heading">Track</span>
        <span class="track-number-heading"> Position</span>
        <span class="isrc-heading">ISRC</span>
      </div>

      <!-- <PlayAudioManager>
        <template #default="{ onStartPlayAudio }"> -->
      <section class="release__section">
        <div
          class="release__row"
          v-for="(song, songIndex) in album.songs"
          v-bind:key="song.track_id"
        >
          <div class="audio__col">
            <play-audio
              ref="playAudio"
              @play="onStartPlayAudio($event, songIndex)"
              :audioSrc="song.preview_url"
            />
          </div>
          <div class="song-title-name__col">
            <router-link
              class="song-title-name"
              :to="'/details/' + song.mtw_id"
            >
              {{ song.title }}
            </router-link>
          </div>

          <div class="track-number-name__col">
            <span class="track-number-name">
              {{ song.track_number }}
            </span>
          </div>

          <div class="isrc__col">
            <span v-if="song.isrc">{{ song.isrc }}</span>
          </div>
        </div>
      </section>
    </div>
    <div class="group-col">
      <div v-if="album" class="group hide-on-tablet" style="width: 100%">
        <label class="inline"><strong>Copyright:</strong></label>
        {{ album.copyright }}
      </div>
    </div>
  </div>
</template>

<script>
import { fetchTracklist } from "@/api/songApi";
import { PlayAudio } from "@/components/common";

export default {
  name: "Release",
  components: {
    PlayAudio,
  },
  metaInfo: {
    title: "Release",
  },
  props: {},
  data() {
    return {
      album: null,
    };
  },
  computed: {},
  methods: {
    onStartPlayAudio(el, index) {
      if (Number.isInteger(this.$options.currentlyPlayingAudioIndex)) {
        this.$refs.playAudio[this.$options.currentlyPlayingAudioIndex].pause();
      }
      this.$options.currentlyPlayingAudioIndex = index;
    },
    async fetchAlbum(collection_id) {
      try {
        let songs = await fetchTracklist(collection_id);
        const artist_names = songs.map((song) => song.artist_name);
        const artist_name = this.getShortestArtistName(artist_names);

        songs = songs.sort((a, b) => a.track_number - b.track_number);
        const { album_name, copyright, release_date } = songs?.[0] || {};

        this.album = {
          artist_name,
          songs,
          copyright,
          release_date,
          album_name,
        };
      } catch (error) {
        console.error(error);
      }
    },
    getShortestArtistName(artist_names) {
      let artist = "";

      new Set(artist_names).forEach((name) => {
        if (!artist) artist = name;
        if (name.length < artist.length) artist = name;
      });

      return artist;
    },
  },
  created() {
    console.log("this", this);
    const { collection_id } = this.$route.params;

    collection_id ? this.fetchAlbum(collection_id) : this.$router.push("/");
  },
};
</script>

<style lang="scss" scoped>
// .headline__container {
//   margin-bottom: 35px;
// }
.headings__container {
  display: none;
  @include tablet {
    display: flex;
    margin-bottom: 1rem;

    & > span {
      color: $color-primary;
    }
  }
}

.release__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 220px;
  }
  @include tablet-landscape-only {
    * {
      font-size: 18px !important;
    }
  }
}

.release__row {
  margin-bottom: 1.25rem;
  display: flex;
}

.audio__col {
  margin-right: 20px;
  // flex-basis: 30px;
  width: 30px;
}

.track-number {
  &-heading {
    flex-basis: 15%;
  }

  &-name {
    color: hsla(204, 4, 23, 1);
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    @include tablet {
      color: $color-text;
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
    }
  }

  &-name__col {
    display: none;
    @include tablet {
      flex-basis: 15%;
      display: block;
    }
  }
}

.song-title {
  &-heading {
    // flex-basis: 52.5%;
    // flex-basis: 100%;
    flex-grow: 1;
  }

  &-name {
    color: $color-text;
    font-size: 16px;
    font-weight: bold;

    @include tablet {
      font-size: 24px;
      line-height: 1.2;
      display: inline-block;
      padding-right: 10px;
    }
  }

  &-name__col {
    flex-grow: 1;
    // flex-basis: calc(100% - 50px);
    padding-right: 10px;
    @include tablet {
      // flex-basis: calc(52.5% - 50px);
      // flex-basis: calc(100% - 50px);
      padding-right: 0;
    }
  }
}

.isrc {
  &-heading {
    flex-basis: 22%;
  }

  &__col {
    display: none;
    @include tablet {
      display: block;
      flex-basis: 22%;
    }
  }
}
</style>
